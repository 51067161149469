// src/post/datasources/cmsCategoriesData.ts

import type { CmsCategoryIds } from "~/src/post/domain/models/Post";
import type { WebContext } from "~/src/Shared/Types";

const cmsCategoriesKings: CmsCategoryIds = {
  0: {},
  1: {
    es: { general: 16, featured: 21, external: 8 },
    en: { general: 24, featured: 25, external: 26 },
    it: { general: 197, featured: 198, external: 199 },
    pt: { general: 371, featured: 372, external: 373 },
    fr: { general: 464, featured: 465, external: 466 },
  },
  2: {
    es: { general: 16, featured: 21, external: 8 },
    en: { general: 24, featured: 25, external: 26 },
    it: { general: 198, featured: 199, external: 200 },
    pt: { general: 239, featured: 240, external: 241 },
    fr: { general: 281, featured: 282, external: 283 },
  },
  3: {
    es: { general: 142, featured: 143, external: 8 },
    en: { general: 144, featured: 145, external: 8 },
    it: { general: 203, featured: 204, external: 8 },
    pt: { general: 379, featured: 380, external: 373 },
    fr: { general: 472, featured: 473, external: 8 },
  },
  4: {
    es: { general: 16, featured: 21, external: 8 },
    en: { general: 24, featured: 25, external: 26 },
    it: { general: 198, featured: 199, external: 200 },
    pt: { general: 223, featured: 225, external: 224 },
    fr: { general: 246, featured: 248, external: 247 },
  },
  5: {
    es: { general: 208, featured: 209, external: 8 },
    en: { general: 210, featured: 211, external: 26 },
    it: { general: 212, featured: 213, external: 26 },
    pt: { general: 374, featured: 375, external: 373 },
    fr: { general: 467, featured: 468, external: 8 },
  },
  6: {
    es: { general: 16, featured: 21, external: 8 },
    en: { general: 24, featured: 25, external: 26 },
    it: { general: 198, featured: 199, external: 200 },
    pt: { general: 239, featured: 240, external: 241 },
    fr: { general: 268, featured: 269, external: 270 },
  },
  8: {
    es: { general: 16, featured: 21, external: 8 },
    en: { general: 24, featured: 25, external: 26 },
    it: { general: 198, featured: 199, external: 200 },
    pt: { general: 239, featured: 240, external: 241 },
    fr: { general: 268, featured: 269, external: 270 },
  },
};

const cmsCategoriesQueens: CmsCategoryIds = {
  0: {},
  1: {
    es: { general: 17, featured: 22, external: 18 },
    en: { general: 28, featured: 29, external: 30 },
    it: { general: 200, featured: 201, external: 202 },
    pt: { general: 376, featured: 377, external: 378 },
    fr: { general: 469, featured: 470, external: 471 },
  },
  2: {
    es: { general: 125, featured: 131, external: 132 },
    en: { general: 28, featured: 29, external: 133 },
    it: { general: 201, featured: 202, external: 203 },
    pt: { general: 242, featured: 243, external: 244 },
    fr: { general: 284, featured: 285, external: 286 },
  },
};

export const getWebContextCmsCategories = (webContext: WebContext): CmsCategoryIds => {
  if (webContext === "queens") {
    return cmsCategoriesQueens;
  } else {
    return cmsCategoriesKings;
  }
};
